html,
body {
  background-color: #f1f1f1;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  padding: 0;
  //width: 800px;
  //height: 1280px !important;
}

a {
  text-decoration: none;
}

* {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: 0.05em;
}

#app {
  width: 100%;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  margin: 0 auto;

  .main-container {
    display: flex;
    flex-grow: 1;
    margin: 0 20px;
  }

  .start-container {
    display: flex;
    flex-grow: 1;
  }

  .fixed-top-bar {
    flex-basis: 70px;
    margin: 20px;
    background: linear-gradient(
      36.65deg,
      #ffffff -94.31%,
      rgba(255, 255, 255, 0.5) 340.41%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    img {
      width: 100px;
    }
  }

  .fixed-bottom-bar {
    //flex-basis: 50px;
    margin: 0 20px;
    .logo-banka {
      height: 40px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  .btn {
    position: relative;
    z-index: 1;
    background: $gold-gradient;
    color: $white;
    border: 2px solid transparent;
    filter: drop-shadow(2px 2px 16px rgba(0, 0, 0, 0.25));

    &:hover {
      color: $white;
    }

    &:focus,
    &:active {
      opacity: 0.8;
      color: $white;
    }

    span {
      display: block;
      line-height: 1;
      font-weight: 600;
      text-transform: uppercase;
    }

    &.button-bottom {
      position: absolute;
      bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
  }

  &.start {
    /*     background-color: #e6dece !important;
    position: absolute;
    top: 0;
    height: 100%; */
  }
}

.narrow-container {
  width: 70%;
  margin: 0 auto;
}

// Forms
input::placeholder {
  color: rgba($black, 0.3);
}
.user-enter {
  input {
    pointer-events: none;
    font-size: 2rem;
    color: $black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: $headings-font-family;
    border: 0;
  }
}

// ICONS
.circle-icon {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: $white;

  &__small {
    width: 50px;
    min-width: 50px;
    height: 50px;
  }
}

// THEMES

.shadow-wrapper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  &__bg-grey {
    background: linear-gradient(
      36.65deg,
      #ffffff -94.31%,
      rgba(255, 255, 255, 0.5) 340.41%
    );
  }
}

.theme-gold {
  .wrapper {
    background-color: rgba($gold, 0.3);
  }
}

.bg-gradient-gold {
  background: $gold-gradient;
}

.bg-gradient-gold-light {
  background: linear-gradient(
    36.65deg,
    #ffffff -94.31%,
    rgba(255, 255, 255, 0.5) 340.41%
  );
}

// Game 1
.game1 {
  .btn {
    background: $orange;
  }

  h2 {
    color: $orange;
  }

  .bg {
    background-color: $orange;
  }

  .progress-square {
    .incorrect {
      background: rgb(177, 177, 177);
    }

    .correct {
      background: $orange;
    }

    .active {
      background-color: $white;
      box-sizing: border-box;
      border: 5px solid $orange;
    }
  }

  &.quiz {
    .answer-section {
      .btn-answer {
        &:hover,
        &:focus {
          /* border: 5px solid $orange; */
          border: 0;
          .animation__active {
            background-color: rgba($orange, 0.2);
          }
        }

        &.wrong {
          //color: $white;
        }

        &.correct {
          //color: $white;
          .animation__correct {
            background-color: rgba($orange, 0.8);
          }
        }
      }
    }
  }
}

.game2 {
  .btn {
    background: $green;
  }

  h2 {
    color: $green;
  }

  .bg {
    background-color: $green;
  }

  .progress-square {
    .incorrect {
      background: rgb(177, 177, 177);
    }

    .correct {
      background: $green;
    }

    .active {
      background-color: $white;
      box-sizing: border-box;
      border: 5px solid $green;
    }
  }

  &.quiz {
    .answer-section {
      .btn-answer {
        &:hover,
        &:focus {
          /* border: 5px solid $green; */
          border: 0;
          .animation__active {
            background-color: rgba($green, 0.2);
          }
        }

        &.wrong {
          //color: $white;
        }

        &.correct {
          //color: $white;
          .animation__correct {
            background-color: rgba($green, 0.8);
          }
        }
      }
    }
  }
}

.game3 {
  .btn {
    background: $green-light;
  }

  h2 {
    color: $green-light;
  }

  .bg {
    background-color: $green-light;
  }

  .progress-square {
    .incorrect {
      background: rgb(177, 177, 177);
    }

    .correct {
      background: $green-light;
    }

    .active {
      background-color: $white;
      box-sizing: border-box;
      border: 5px solid $green-light;
    }
  }

  &.quiz {
    .answer-section {
      .btn-answer {
        &:hover,
        &:focus {
          /* border: 5px solid $green-light; */
          border: 0;
          .animation__active {
            background-color: rgba($green-light, 0.2);
          }
        }

        &.wrong {
          //color: $white;
        }

        &.correct {
          //color: $white;
          .animation__correct {
            background-color: rgba($green-light, 0.8);
          }
        }
      }
    }
  }
}

.game4 {
  .btn {
    background: $blue;
  }

  h2 {
    color: $blue;
  }

  .bg {
    background-color: $blue;
  }

  .progress-square {
    .incorrect {
      background: rgb(177, 177, 177);
    }

    .correct {
      background: $blue;
    }

    .active {
      background-color: $white;
      box-sizing: border-box;
      border: 5px solid $blue;
    }
  }

  &.quiz {
    .answer-section {
      .btn-answer {
        &:hover,
        &:focus {
          /* border: 5px solid $blue; */
          border: 0;
          .animation__active {
            background-color: rgba($blue, 0.2);
          }
        }

        &.wrong {
          //color: $white;
        }

        &.correct {
          //color: $white;
          .animation__correct {
            background-color: rgba($blue, 0.8);
          }
        }
      }
    }
  }
}

.game5 {
  .btn {
    background: $gold;
  }

  h2 {
    color: $gold;
  }

  .bg {
    background-color: $gold;
  }

  .progress-square {
    .incorrect {
      background: rgb(177, 177, 177);
    }

    .correct {
      background: $gold;
    }

    .active {
      background-color: $white;
      box-sizing: border-box;
      border: 5px solid $gold;
    }
  }

  &.quiz {
    .answer-section {
      .btn-answer {
        &:hover,
        &:focus {
          /* border: 5px solid $gold; */
          border: 0;
          .animation__active {
            background-color: rgba($gold, 0.2);
          }
        }

        &.wrong {
          //color: $white;
        }

        &.correct {
          //color: $white;
          .animation__correct {
            background-color: rgba($gold, 0.8);
          }
        }
      }
    }
  }
}

.game6 {
  .btn {
    background: $grey;
  }

  h2 {
    color: $grey;
  }

  .bg {
    background-color: $grey;
  }

  .progress-square {
    .incorrect {
      background: rgb(177, 177, 177);
    }

    .correct {
      background: $grey;
    }

    .active {
      background-color: $white;
      box-sizing: border-box;
      border: 5px solid $grey;
    }
  }

  &.quiz {
    .answer-section {
      .btn-answer {
        &:hover,
        &:focus {
          /* border: 5px solid $grey; */
          border: 0;
          .animation__active {
            background-color: rgba($grey, 0.2);
          }
        }

        &.correct {
          //color: $white;
          .animation__correct {
            background-color: rgba($grey, 0.8);
          }
        }
      }
    }
  }
}

.quizbonus {
  .progress-square {
    .incorrect {
      background: rgb(177, 177, 177);
    }

    .correct {
      background: $orange;
    }

    .active {
      background-color: rgb(75, 74, 74);
    }
  }
  &.quiz {
    .question-section {
      p {
        color: $white;
      }
    }

    .answer-section {
      .btn-answer {
        height: 160px;
        background: $white;
        &:hover,
        &:focus {
          border: 0;
        }

        &.wrong {
          background-color: rgba($grey, 0.2);
          color: $white;
        }

        &.correct {
          background-color: $green;
          color: $white;
        }
      }
    }
  }

  .btn-confirm,
  .calc,
  .hint {
    display: none;
  }
}

.game1-bg-light {
  background: rgba($orange, 0.65);
}

.game1-bg-light-30 {
  background: rgba($orange, 0.2);
}

.game2-bg-light {
  background: rgba($green, 0.65);
}

.game3-bg-light {
  background: rgba($green-light, 0.65);
}

.game4-bg-light {
  background: rgba($blue, 0.65);
}

.game5-bg-light {
  background: rgba($gold, 0.65);
}

.game6-bg-light {
  background: rgba($grey, 0.65);
}

.tapping-bg-light {
  background-color: $blue;
  margin: 10px;
}

.quizbonus-bg-light {
  background-color: $green;
  margin: 10px;
}

// Animations

.float {
  animation: float 6s ease-in-out infinite;

  &__d1 {
    animation-delay: 0s;
  }
  &__d2 {
    animation-delay: 0.5s;
  }
  &__d3 {
    animation-delay: 1s;
  }
}
@keyframes float {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-15px);
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
}

.lock {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  z-index: 5;

  path {
    stroke: none;
    stroke-width: 1;
    stroke-dasharray: none;
    stroke-linecap: butt;
    stroke-dashoffset: 0;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    fill: rgb(0, 0, 0);
    fill-rule: nonzero;
    opacity: 1;
  }
}

.back-button {
  width: 100px;
  margin: 0 auto;
  font-weight: bold;
  background: transparent !important;
  color: #a48242 !important;
  font-size: 1.1rem;
}

.admin {
  .user-game-item {
    width: 100%;
    color: $white;
    display: block;
    font-size: 9px;
  }
  .current-game {
    min-height: 100vh;
    position: relative;
    .summary {
      background-color: $green-light;
    }
    h2 {
      font-size: 18px;
      color: $white;
    }
    h3 {
      font-size: 14px;
      color: $white;
    }

    .new-game {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .btn {
    font-family: $font-family-sans-serif;
    font-size: 15px;
  }
  .addTask {
    .form-control {
      font-size: 11px;
      border: 1px solid $gold;
      padding: 10px 15px;
      color: $grey-light;
    }
  }
}

// Meter
/* add css module styles here (optional) */

/* .test {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
} */
._1alIC {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  //margin: 10px;
  border: none;
  padding: 10px;
  background-color: rgba(202, 122, 102, 0.9);
  border-radius: 0;
  display: flex;
  z-index: 9999;
  color: #fff;
  flex-direction: column;
}

._nqCmj {
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 600;
  padding: 5px 0;
}

._2Qem8 {
  flex-grow: 1;
  font-size: 0.8rem;
}
