.dashboard {
  .badges {
    div[class*="game"] {
      position: relative;
      text-align: center;
    }

    .game-icon {
      width: 90%;
      height: 90%;
    }

    .badge {
      svg {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }

    .open .badge,
    .closed .badge {
      font-family: $headings-font-family;
      font-size: 1.5rem;
      position: absolute;
      width: 100%;
      height: 42px;
      text-align: center;
      top: calc(50% - 30px);
      left: 50%;
      opacity: 0.8;
      transform: translateX(-50%);
    }

    .closed,
    .open {
      // Circle BG
      g {
        circle {
          fill: #dadada;
        }
        path {
          fill: $grey;
          stroke: none;
          opacity: 0.3;
        }
        rect {
          stroke: none;
        }
      }

      p {
        color: $grey;
      }
    }

    .closed,
    .completed {
      pointer-events: none;
    }

    .open {
      cursor: pointer;
    }

    .game1 {
      color: $orange;

      .badge {
        background-color: $orange;
        svg {
          stop {
            stop-color: $white;
          }

          g path {
            fill: $orange;
          }
        }
      }
    }

    .game2 {
      color: $green;

      .badge {
        background-color: $green;
        svg {
          stop {
            stop-color: $white;
          }

          g path {
            fill: $green;
          }
        }
      }
    }

    .game3 {
      color: $green-light;

      .badge {
        background-color: $green-light;

        svg {
          stop {
            stop-color: $white;
          }

          g path {
            fill: $green;
          }
        }
      }
    }

    .game4 {
      color: $blue;
      .badge {
        background-color: $blue;
        svg {
          stop {
            stop-color: $white;
          }

          g path {
            fill: $blue;
          }
        }
      }
    }

    .game5 {
      color: $gold;
      .badge {
        background-color: $gold;
        svg {
          stop {
            stop-color: $white;
          }

          g path {
            fill: $gold;
          }
        }
      }
    }
    .game6 {
      color: $grey;
      .badge {
        background-color: $grey;

        svg {
          stop {
            stop-color: $white;
          }

          g path {
            fill: $grey;
          }
        }
      }
    }
  }
}
