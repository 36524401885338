// Quiz

.intro {
  .badge-top {
    position: relative;
    display: inline-block;
    svg {
      width: 160px;
      height: 160px;
    }

    h3 {
      position: absolute;
      color: $white;
      text-align: center;
      font-size: 1.4rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  .badge-corner {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.quiz {
  .question-section {
    img {
      width: 100%;
    }
  }

  .answer-section {
    .animation {
      display: none;
      position: absolute;
      transform: none;
      border-radius: $border-radius;
      color: $white;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      .icon {
        font-size: 3rem;
      }
    }

    &.answer-is-text {
      .animation {
        align-items: center;
        flex-direction: row-reverse;
      }
    }

    &.answer-is-image {
      .btn-answer {
        background: transparent;
        border: 0;
        padding: 0;
        box-shadow: none;

        .animation {
          align-items: center;
          justify-content: center;
        }

        img {
          pointer-events: none;
          border-radius: $border-radius;
          // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }

    /*     .disable {
      .btn {
        pointer-events: none;
        opacity: 0.2;
      }
    } */
    .disable {
      .btn {
        pointer-events: none;
        opacity: 0.2;
      }
    }

    .btn-answer {
      position: relative;
      transition: none;
      border: 0;
      background-color: $white;
      color: $black;
      font-family: Arial;
      font-size: 1rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:hover,
      &:focus {
        border: 5px solid black;
        .animation__active {
          display: flex;
          //pointer-events: none;
        }
      }

      &:disabled {
        opacity: 1;
      }

      &.wrong {
        pointer-events: none;

        .animation__wrong {
          display: flex;
          background-color: rgba($grey, 0.4);
        }
      }

      &.correct {
        pointer-events: none;
        //background: green !important;

        .animation__correct {
          display: flex;
        }
      }

      &.btn-image {
        cursor: pointer;
        width: 100%;
        padding: 0;
        border: 0;
        transform: skewX(0deg);

        img {
          width: 100%;
          border: 5px solid #fff;
        }

        &.correct {
          filter: brightness(100%);
          img {
            filter: brightness(30%);
          }
          &:before {
            //background: url("/assets/images/icons/icon-check-c.svg") no-repeat;
            background-size: 100%;
            z-index: 2;
            width: 25%;
            height: 25%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.wrong {
          filter: brightness(100%);
          img {
            filter: brightness(30%);
          }
          &:before {
            //background: url("/assets/images/icons/icon-cross-c.svg") no-repeat;
            background-size: 100%;
            z-index: 2;
            width: 25%;
            height: 34%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .calc,
  .hint {
    cursor: pointer;
  }

  .hint {
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .hint-confirm {
    z-index: 1;
    width: 200px;
  }

  .lightbox {
    position: absolute;
    z-index: 2;
    background-color: rgba($gold, 0.8);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    video {
      width: 100%;
    }

    &.milestone {
      background: rgba($gold, 1) no-repeat;
      //background-size: 80%;
      //background-position: center center;
      > :before {
        content: " ";
        z-index: -1;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        opacity: 0.1;
        filter: brightness(0) invert(1);

        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
      }

      &.deset-v-vrsto {
        > :before {
          background-image: url("/assets/images/icon-milestone1.svg");
        }
      }

      &.raketni-tek {
        > :before {
          background-image: url("/assets/images/icon-milestone2.svg");
        }
      }
    }
  }

  .video-playing form {
    filter: blur(8px);
  }
}

.current-game-status {
  .item {
    width: 130px;
    img {
      filter: brightness(0) invert(1);
      height: 20px;
    }
  }

  .item {
    transition: all 0.2s ease-in-out;
    &.isanimating {
      transform: scale(1.15);
      filter: contrast(1.75);

      img,
      span {
        transform: scale(1.15);
      }

      &.hint-activated {
        background-color: red;
      }
    }

    &.quizbonus-bg-light {
      &.isanimating {
        filter: contrast(1.2);
      }
    }
  }
}

// Progress

.progress-square {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;

  .line {
    position: absolute;
    z-index: 0;
    background-color: #fff;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 3px;
    width: 90%;
    margin: 0 30px;
    margin: 0 auto;
    left: 50%;
  }

  .sq {
    display: inline-block;
    position: relative;
    z-index: 1;
    //padding: 5px 0;
    margin: 0 8px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: $white;
  }
}

// LEADERBOARD

.leaderboard {
  .progress {
    height: 35px;

    .progress-bar {
    }
  }

  .item {
    background: linear-gradient(
      36.65deg,
      #ffffff -94.31%,
      rgba(255, 255, 255, 0.5) 340.41%
    );
    opacity: 0.8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    .left {
      img {
        height: 42px;
        width: 79px;
      }
    }

    .right {
      img {
        height: 20px;
      }
    }

    .center {
      left: 50%;
      transform: translateX(-50%);
    }

    &.notcompleted {
      background: rgba($white, 0.3);
      .right {
        filter: blur(5px);
      }
    }

    .loader {
      width: 40px;
      height: 40px;
    }
  }
}

// BONUS GAMES

.bonus {
  .treat {
    --scale-x: 0;
    --scale-y: 0;
    pointer-events: none;
    display: block;
    position: absolute;
    z-index: 0;
    top: calc(50% + 0.5rem);
    left: calc(50% - 0.5rem);
    border-radius: 50%;
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8vmin;
    transform: translate(calc(var(--x) * 1px), calc(var(--y) * 1px))
      translate(-50%, -50%);
    pointer-events: none;
    animation: treat-enter 0.1s ease-in backwards,
      treat-exit 300ms linear calc((var(--lifetime, 3000) * 1ms) - 300ms)
        forwards;

    @keyframes treat-enter {
      from {
        opacity: 0;
      }
    }
    @keyframes treat-exit {
      to {
        opacity: 0;
      }
    }

    .inner {
      animation: inner-rotate 0.6s linear infinite;
      transform: rotate(calc(-1turn * var(--direction)));
      @keyframes inner-rotate {
        to {
          transform: none;
        }
      }
    }
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  .action-button {
    background-color: $blue;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    border: 0;
    outline: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}

.banknote {
  .elements {
    height: 90px;
    position: relative;

    .dropdown {
      position: static;
    }

    .dropdown-menu.show {
      width: 100%;
      transform: none !important;
      top: 65px !important;
      border: 0;
      background: rgba($gold-light, 0.7);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }

    #dropdown-elements {
      position: relative;
      &:before {
        content: " ";
        z-index: -1;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background: url("/assets/images/icon_elementi.svg") no-repeat;
        background-size: 100%;
      }
    }

    #dropdown-icons {
      position: relative;
      &:before {
        content: " ";
        z-index: -1;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background: url("/assets/images/icon_ikonce.svg") no-repeat;
        background-size: 100%;
      }
    }

    #dropdown-colors {
      position: relative;
      &:before {
        content: " ";
        z-index: -1;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background: url("/assets/images/icon_barve.svg") no-repeat;
        background-size: 100%;
      }
    }

    .dropdown-toggle {
      background: transparent;
      color: $grey;
      text-transform: uppercase;
      font-family: Arial;
      font-size: 1rem;
      padding-right: 0;
    }

    .dropdown-menu {
      p {
        line-height: 1.2;
        margin-top: 20px;
        margin-bottom: 5px;
      }
      button {
        outline: 0;
        background: none;
        border: 0;
        display: inline-block;
        margin-bottom: 5px;
        width: 70px;
        img {
          width: 100%;
        }
      }

      .stevilka {
        button {
          width: 50px;
        }
      }
    }
  }

  .note {
    position: relative;
    width: 560px;
    height: 300px;
    margin-top: 150px;
    background: #f4edd0;
    box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.25);

    > div {
      position: absolute;
    }

    .crtice {
      width: 21px;
      height: 100%;

      &__1 {
        background: url("/assets/images/banknote/crtice1.svg") repeat-y;
      }
      &__2 {
        background: url("/assets/images/banknote/crtice2.svg") repeat-y;
      }

      &__3 {
        background: url("/assets/images/banknote/crtice3.svg") repeat-y;
      }

      &__4 {
        background: url("/assets/images/banknote/crtice4.svg") repeat-y;
      }

      &__left {
        left: 0;
      }

      &__right {
        right: 0;
        transform: rotate(180deg);
      }
    }

    .stevilka {
      width: 100%;
      height: 81px;
      top: 10%;

      opacity: 0;
      transition: opacity 1s;

      &__5 {
        background: url("/assets/images/banknote/5_dark.svg") no-repeat;
        opacity: 1;
        left: 19%;
        transition: opacity 1s;
      }

      &__10 {
        background: url("/assets/images/banknote/10_dark.svg") no-repeat;
        opacity: 1;
        left: 21%;

        transition: opacity 1s;
      }

      &__20 {
        background: url("/assets/images/banknote/20_dark.svg") no-repeat;
        opacity: 1;
        left: 19%;
        transition: opacity 1s;
      }

      &__50 {
        background: url("/assets/images/banknote/50_dark.svg") no-repeat;
        opacity: 1;
        transition: opacity 1s;
        left: 19%;
      }

      &__100 {
        background: url("/assets/images/banknote/100_dark.svg") no-repeat;
        opacity: 1;
        left: 21%;
        transition: opacity 1s;
      }

      &__200 {
        background: url("/assets/images/banknote/200_dark.svg") no-repeat;
        opacity: 1;
        left: 19%;
        transition: opacity 1s;
      }

      &__500 {
        background: url("/assets/images/banknote/500_dark.svg") no-repeat;
        opacity: 1;
        left: 19%;
        transition: opacity 1s;
      }
    }

    .stevilka-color {
      width: 100%;
      height: 56px;
      bottom: 5%;
      left: 5%;
      opacity: 0;
      transition: opacity 1s;
      &__5 {
        background: url("/assets/images/banknote/5.svg") no-repeat;
        opacity: 1;
        transition: opacity 1s;
      }

      &__10 {
        background: url("/assets/images/banknote/10.svg") no-repeat;
        opacity: 1;
        transition: opacity 1s;
      }

      &__20 {
        background: url("/assets/images/banknote/20.svg") no-repeat;
        opacity: 1;
        transition: opacity 1s;
      }

      &__50 {
        background: url("/assets/images/banknote/50.svg") no-repeat;
        opacity: 1;
        transition: opacity 1s;
      }

      &__100 {
        background: url("/assets/images/banknote/100.svg") no-repeat;
        opacity: 1;
        transition: opacity 1s;
      }

      &__200 {
        background: url("/assets/images/banknote/200.svg") no-repeat;
        opacity: 1;
        transition: opacity 1s;
      }

      &__500 {
        background: url("/assets/images/banknote/500.svg") no-repeat;
        opacity: 1;
        transition: opacity 1s;
      }
    }

    .nit {
      width: 7px;
      height: 100%;
      top: 0;
      left: 29%;
      &__1 {
        background: url("/assets/images/banknote/nit1.svg") repeat-y;
      }
      &__2 {
        background: url("/assets/images/banknote/nit2.svg") repeat-y;
      }
      &__3 {
        background: url("/assets/images/banknote/nit3.svg") repeat-y;
      }
      &__4 {
        background: url("/assets/images/banknote/nit4.svg") repeat-y;
      }
    }

    .arhitekt {
      right: 9.7%;
      bottom: 22%;
      width: 45px;
      height: 83px;

      &__1 {
        background: url("/assets/images/banknote/arhitekt1.svg") no-repeat;
        background-size: 100%;
      }
      &__2 {
        background: url("/assets/images/banknote/arhitekt2.svg") no-repeat;
        background-size: 100%;
      }
      &__3 {
        background: url("/assets/images/banknote/arhitekt3.svg") no-repeat;
        background-size: 100%;
      }
      &__4 {
        background: url("/assets/images/banknote/arhitekt4.svg") no-repeat;
        background-size: 100%;
      }
    }

    .mavricne {
      right: 10.5%;
      bottom: 0;
      width: 39px;
      height: 48px;
      &__1 {
        background: url("/assets/images/banknote/mavricne1.svg") no-repeat;
        background-size: 100%;
      }
      &__2 {
        background: url("/assets/images/banknote/mavricne2.svg") no-repeat;
        background-size: 100%;
      }
      &__3 {
        background: url("/assets/images/banknote/mavricne3.svg") no-repeat;
        background-size: 100%;
      }
      &__4 {
        background: url("/assets/images/banknote/mavricne4.svg") no-repeat;
        background-size: 100%;
      }
    }

    .mavricne-blank {
      right: 10.5%;
      top: 0;
      width: 39px;
      height: 48px;
      &__1 {
        background: url("/assets/images/banknote/mavricne1_blank.svg") no-repeat;
        background-size: 100%;
      }
      &__2 {
        background: url("/assets/images/banknote/mavricne2_blank.svg") no-repeat;
        background-size: 100%;
      }
      &__3 {
        background: url("/assets/images/banknote/mavricne3_blank.svg") no-repeat;
        background-size: 100%;
      }
      &__4 {
        background: url("/assets/images/banknote/mavricne4_blank.svg") no-repeat;
        background-size: 100%;
      }
    }

    .portret {
      right: 9.7%;
      top: 22%;
      width: 45px;
      height: 62px;
      &__1 {
        background: url("/assets/images/banknote/portret1.svg") no-repeat;
        background-size: 100%;
      }
      &__2 {
        background: url("/assets/images/banknote/portret2.svg") no-repeat;
        background-size: 100%;
      }
      &__3 {
        background: url("/assets/images/banknote/portret3.svg") no-repeat;
        background-size: 100%;
      }
      &__4 {
        background: url("/assets/images/banknote/portret4.svg") no-repeat;
        background-size: 100%;
      }
    }

    .vodniznak {
      width: 74px;
      height: 101px;
      bottom: 35%;
      left: 5.5%;
      opacity: 0;
      transition: opacity 1s;
      &__1 {
        background: url("/assets/images/banknote/vodniznak1.svg") no-repeat;
        background-size: 100%;
        opacity: 0.4;
        transition: opacity 1s;
      }
      &__2 {
        background: url("/assets/images/banknote/vodniznak2.svg") no-repeat;
        background-size: 100%;
        opacity: 0.4;
        transition: opacity 1s;
      }
      &__3 {
        background: url("/assets/images/banknote/vodniznak3.svg") no-repeat;
        background-size: 100%;
        opacity: 0.4;
        transition: opacity 1s;
      }
      &__4 {
        background: url("/assets/images/banknote/vodniznak4.svg") no-repeat;
        background-size: 100%;
        opacity: 0.4;
        transition: opacity 1s;
      }
    }

    .icon {
      position: absolute;

      img {
        width: 70px;
        height: 70px;
      }

      &__1 {
        bottom: 28%;
        left: 54%;
      }

      &__2 {
        bottom: 5%;
        left: 40%;
      }

      &__3 {
        left: 60%;
        bottom: 65%;
      }

      &__4 {
        left: 5%;
        top: 2%;
      }
    }
  }

  .color1 {
    background-color: #ead3d7 !important;
  }
  .color2 {
    background-color: #b8e4e0 !important;
  }
  .color3 {
    background-color: #ffe2c7 !important;
  }
  .color4 {
    background-color: #dcf0cc !important;
  }
  .color5 {
    background-color: #f4edd0 !important;
  }
  .color6 {
    background-color: #d5d0ea !important;
  }

  .absolute-button {
    //position: absolute;
    //bottom: 80px;
  }

  .btn-reset {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px !important;
    background: $gold-gradient !important;
  }
}

.finalcode {
  .codenum {
    span {
      color: $green;
      font-family: $headings-font-family;
      font-size: 4rem;
      font-weight: 900;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      background: rgba($white, 0.5);
      display: inline-block;
      padding: 1rem;
      margin: 0.5rem;
      line-height: 1;
    }
  }
}

.calculator-wrapper {
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150px;

  svg {
    width: 50px;
    height: 50px;
    left: 0;
    display: none;
  }
}

.calculator .operator-keys {
  background: $gold-gradient !important;
}

.calculator .digit-keys .calculator-key,
.calculator .function-keys .calculator-key {
  color: black;
}

.tapping-game {
  svg {
    g {
      path {
        fill: $blue;
      }
    }
  }
}

.bonus-game {
  svg {
    g {
      path {
        fill: $green;
      }
    }
  }
}
